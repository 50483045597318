import api from '@/api'
import { createUser, updateUser } from '@/services/user.service'
import { createStore } from 'vuex'
import { User } from '../models/user'
// import { googleLogout } from 'vue3-google-login'

type State = {
  loading: boolean
  errorResponse: any
  token: string | null
  version: string | null
  lastModified: Date | null
  viewer: User | null
  view: string | null
}
const TOKEN_KEY = 'JANSCORE'

export default createStore({
  state: {
    loading: false,
    errorResponse: null,
    token: localStorage.getItem(TOKEN_KEY) ?? null,
    version: null,
    lastModified: null,
    viewer: null,
    view: null,
  },

  getters: {
    isAuthenticated: (state: State) => !!state.viewer,
    viewer: (state: State) => state.viewer,
    view: (state: State) => state.view ?? 'JanScore',
  },

  mutations: {
    loading(state: State, value: boolean) {
      state.loading = value
    },

    errorResponse(state: State, value: any) {
      state.errorResponse = value
    },

    token(state: State, token: string | null) {
      if (token) {
        localStorage.setItem(TOKEN_KEY, token)
      } else {
        localStorage.removeItem(TOKEN_KEY)
      }
      state.token = token
    },

    version(state: State, data) {
      state.version = data.version
      state.lastModified = data.lastModified
    },

    viewer(state: State, viewer: User) {
      state.viewer = viewer
    },

    view(state: State, view: string) {
      state.view = view
    },
  },

  actions: {
    async authenticate({ state, getters, commit }: { state: any; getters: any; commit: any }) {
      if (getters.isAuthenticated) return
      if (state.token) {
        const viewer = await api
          .get('viewer')
          .then((resp: any) => User.createFromJson(resp.data))
          .catch(() => null)

        commit('viewer', viewer ?? null)
      }
    },

    async signIn({ commit }: { commit: any }, userData) {
      commit('loading', true)

      const token = await api
        .post('token', { email: userData.email })
        .then((resp: any) => resp.data)
        .catch(() => null)
      commit('token', token)

      if (token) {
        const invitation = localStorage.getItem('INVITATION') ?? null
        const viewer = await api
          .get('viewer')
          .then((resp: any) => User.createFromJson(resp.data))
          .catch(() => null)

        if (viewer) {
          // 既にどこかのコミュニティでユーザー生成されている場合
          if (!viewer.snsId) {
            await updateUser(viewer.id, { snsId: userData.sub, picture: userData.picture })
            viewer.snsId = userData.sub
            viewer.picture = userData.picture
          }
          commit('viewer', viewer)
        } else {
          if (invitation) {
            // 招待されている場合
            const user = await updateUser(invitation, {
              email: userData.email,
              snsId: userData.sub,
              picture: userData.picture,
            })
            commit('viewer', user)
          } else {
            const user = await createUser({
              snsId: userData.sub,
              name: userData.name,
              email: userData.email,
              picture: userData.picture,
              isGoogle: true,
            })
            commit('viewer', user)
          }
        }
        localStorage.removeItem('INVITATION')
      }

      commit('loading', false)
    },

    async signInWithLine({ commit }: { commit: any }, data: { code: string; redirectUri: string }) {
      const url = 'https://api.line.me/oauth2/v2.1/token'
      const params = new URLSearchParams()
      params.append('grant_type', 'authorization_code')
      params.append('code', data.code)
      params.append('redirect_uri', data.redirectUri)
      params.append('client_id', '2006706148')
      params.append('client_secret', '43ba4f22ccc0b579c37879d80559e27f')
      const response = await api
        .post(url, params, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
        .then((resp: any) => resp?.data || null)
        .catch(() => null)

      if (response) {
        const token = await api
          .post('auth/line', response)
          .then((resp: any) => resp.data)
          .catch(() => null)
        commit('token', token)

        const viewer = await api
          .get('viewer')
          .then((resp: any) => User.createFromJson(resp.data))
          .catch(() => null)
        if (viewer) {
          commit('viewer', viewer)
        } else {
          const user = await createUser({ idToken: response.id_token })
          commit('viewer', user)
        }
      }
    },

    async signOut({ commit }: { commit: any }) {
      commit('token', null)
      commit('viewer', null)
      // googleLogout()
    },
  },
  modules: {},
})
